import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";
import { orArray, orEmpty, orNull, orNumber } from "utils/Selector";
import TransactionApi from "apis/TransactionApi";
import TemplateApi from "apis/TemplateApi";

const initialState = {
  isRefresh: false as boolean,
  templates: [] as TemplateType[],
  detail: null as TemplateType | null,
};

export const templates = createAsyncThunk(
  "template/templates",
  async (_, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await TemplateApi.templates();
      const { data } = response;
      if (data.meta.success) {
        return data.data as TemplateType[];
      }
      return [];
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const detailTemplate = createAsyncThunk(
  "template/detailTemplate",
  async (templateId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await TemplateApi.detailTemplate(templateId);
      const { data } = response;
      return data.data as TemplateType;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const updatelTemplate = createAsyncThunk(
  "template/updatelTemplate",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await TemplateApi.updatelTemplate(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: "Cập nhật thành công!",
          })
        );
        return data.data;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã xảy ra lỗi!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const TemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
    setSelections: (state, action: PayloadAction<SelectionType[]>) => {
      let newDetail = { ...state.detail } as TemplateType;
      newDetail.selections = action.payload;
      state.detail = newDetail;
    },
    setDetail: (state, action: PayloadAction<TemplateType>) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(templates.fulfilled, (state, action) => {
      if (action.payload) {
        state.templates = action.payload;
      }
    });
    builder.addCase(detailTemplate.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(updatelTemplate.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
  },
});

export default TemplateSlice;
