import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

type Props = {
    filter: any;
    onSearch: (params: any) => void;
};

export default function Filter(props: Props) {
    const { filter } = props;

    const onChangeDate = (dates: any) => {
        const params = {
            from: moment(`${dates[0]}`).format(dateFormat),
            to: moment(`${dates[1]}`).format(dateFormat),
        };
        props.onSearch(params);
    };
    return (
        <div className={"mt-10"}>
            <RangePicker
                allowClear={false}
                onChange={onChangeDate}
                value={[
                    dayjs(
                        filter
                            ? filter.from
                            : moment().subtract(30, "d").format(dateFormat),
                        dateFormat
                    ),
                    dayjs(filter ? filter.to : moment().format(dateFormat), dateFormat),
                ]}
                format={dateFormat}
            />
        </div>
    );
}