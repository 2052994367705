import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";
import { orArray, orEmpty, orNull, orNumber } from "utils/Selector";
import TransactionApi from "apis/TransactionApi";
import SettingApi from "apis/SettingApi";

const initialState = {
  isRefresh: false as boolean,
  settings: [] as SettingType[],
};

export const settings = createAsyncThunk(
  "setting/settings",
  async (_, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await SettingApi.settings();
      const { data } = response;
      if (data.meta.success) {
        return data.data as SettingType[];
      }
      return [];
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const updateSetting = createAsyncThunk(
  "setting/updateSetting",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await SettingApi.updateSetting(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: "Cập nhật thành công!",
          })
        );
        return data.data as SettingType;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã xảy ra lỗi!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(settings.fulfilled, (state, action) => {
      if (action.payload) {
        state.settings = action.payload;
      }
    });
    builder.addCase(updateSetting.fulfilled, (state, action) => {
      if (action.payload) {
        state.settings = state.settings.map((itemSetting) => {
          if (itemSetting.key === action.payload?.key) {
            return { ...itemSetting, ...action.payload };
          }
          return itemSetting;
        });
      }
    });
  },
});

export default SettingSlice;
