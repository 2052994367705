import { RequestManager } from "utils";
import config from "configs";

const getGeneralData = (params: any) => {
    return RequestManager.v1.withAuthorize.get(
        `${config.api_base_url}/admin/statics/general`,
        params
    );
};

const getTransactionData = (params: any) => {
    return RequestManager.v1.withAuthorize.get(
        `${config.api_base_url}/admin/statics/transactions`,
        params
    );
};

const getDailyTransactionData = (params: any) => {
    return RequestManager.v1.withAuthorize.get(
        `${config.api_base_url}/admin/statics/daily-transactions`,
        params
    );
};

export default {
    getGeneralData,
    getTransactionData,
    getDailyTransactionData
};
