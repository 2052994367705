import {Divider, Flex, Form, Input, InputNumber, Table, Typography} from "antd";
import { textEllipseMiddle } from "pages/finances/components/List";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import TemplateSlice from "stores/TemplateSlice";

export default function Selections() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { detail } = useAppSelector((state) => state.template);

  const onChangeRate = (value: number | null, item: SelectionType) => {
    if (detail) {
      let selections = [...detail.selections];
      const index = selections.findIndex((s) => s._id === item._id);
      selections[index] = {
        ...selections[index],
        rate: value || selections[index].rate,
      };
      dispatch(TemplateSlice.actions.setSelections(selections));
    }
  };

  return (
    <div style={{ background: "#fff", padding: 24, marginTop: 24 }}>
      <Typography.Title level={4}>
        Cài đặt trả thưởng
      </Typography.Title>
      <Divider />
      <Form form={form} component={false}>
        <Flex wrap="wrap" gap="large">
          {detail &&
            detail.selections.map((item) => (
              <Flex key={item._id} vertical>
                <Typography.Text strong>
                  {detail.type === "TOTAL_SCORE"
                    ? `Tổng ${item.name}`
                    : detail.type === "DICE_OPEN"
                    ? `Mặt ${item.name}`
                    : item.name}
                </Typography.Text>
                <Flex align="center">
                  1 ={" "}
                  <InputNumber
                    style={{ marginLeft: 4 }}
                    value={item.rate}
                    onChange={(value) => onChangeRate(value, item)}
                    placeholder="Tỉ lệ"
                  />
                </Flex>
              </Flex>
            ))}
        </Flex>
      </Form>
    </div>
  );
}
