import {
  Button,
  ConfigProvider,
  Dropdown,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import ModalUpdateUser from "./ModalUpdateUser";
import ModalDeposit from "./ModalDeposit";
import { formatNumberLocal } from "utils";
import UserSlice from "stores/UserSlice";
import { useNavigate } from "react-router-dom";

type Props = {
  filter: any;
  onChangePage: (page: number) => void;
};

export default function List(props: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { users, userSelected } = useAppSelector((state) => state.user);

  const [modalOpen, setModalOpen] = useState("");
  const [modalOpenUpdateUser, setModalOpenUpdateUser] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <Space>
          <img src="/images/ic_update.svg" alt="" /> Chi tiết
        </Space>
      ),
    },
    {
      key: "2",
      label: (
        <Space onClick={() => setModalOpen("DEPOSIT")}>
          <img src="/images/ic_deposit_black.svg" alt="" /> Nạp tiền
        </Space>
      ),
    },
    {
      key: "3",
      label: (
        <Space onClick={() => setModalOpen("WITHDRAW")}>
          <img src="/images/ic_withdraw_black.svg" alt="" /> Rút tiền
        </Space>
      ),
    },
  ];
  const columns = [
    {
      title: "id",
      dataIndex: "shortId",
      key: "id",
    },

    {
      title: "Tên đăng nhập",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Số dư ví",
      dataIndex: "userCoin",
      key: "userCoin",
      render: (userCoin: UserCoinType) => (
        <Typography.Text strong>{`${formatNumberLocal(
          userCoin.balance
        )}K`}</Typography.Text>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => `${moment(value).format("DD / MM / YYYY")}`,
    },
    {
      title: "Trạng thái",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: boolean) => (
        <Typography.Text type={value ? "success" : "danger"} strong>
          {value ? "Hoạt động" : "Tạm khoá"}
        </Typography.Text>
      ),
    },
    {
      title: "Thao tác",
      key: "action",
      render: (_: any, record: any) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#009292",
              borderRadius: 50,
            },
          }}
        >
          <Dropdown
            menu={{
              items,
              onClick: ({ _, key }: any) =>
                key == "1"
                  ? navigate(`/user/${record.id}`)
                  : dispatch(UserSlice.actions.setUserSelected(record)),
            }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <Button>Thao tác</Button>
          </Dropdown>
        </ConfigProvider>
      ),
    },
  ];
  return (
    <div className="table-wrapper">
      <Table
        className="table-custom"
        dataSource={users.datas.map((item) => ({ key: item.id, ...item }))}
        //@ts-ignore
        columns={columns}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
      <ModalUpdateUser
        modalOpen={modalOpenUpdateUser}
        setModalOpen={setModalOpenUpdateUser}
      />
      <ModalDeposit
        modalOpen={!!modalOpen}
        actionType={modalOpen}
        setModalOpen={setModalOpen}
      />
    </div>
  );
}
