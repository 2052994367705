import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";
import { orArray, orEmpty, orNull, orNumber } from "utils/Selector";
import TransactionApi from "apis/TransactionApi";

const initialState = {
  isRefresh: false as boolean,
  transactions: {
    datas: [] as TransactionType[],
    meta: defaultMeta as MetaType,
  },
  staticTransaction: {
      totalTransaction: 0 as number,
      totalPendingDeposit: 0 as number,
      totalPendingWithdraw: 0 as number
  }
};

export const transactions = createAsyncThunk(
  "transaction/transactions",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await TransactionApi.transactions(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const staticTransaction = createAsyncThunk(
    "transaction/staticTransaction",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await TransactionApi.staticTransaction(params);
            const { data } = response;
            return {
                totalTransaction: data.data.totalTransaction,
                totalPendingWithdraw: data.data.totalPendingWithdraw,
                totalPendingDeposit: data.data.totalPendingDeposit
            };
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const accepted = createAsyncThunk(
  "game/accepted",
  async (params: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await TransactionApi.accepted(params);
      const { data, meta } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: "Duyệt yêu cầu thành công!",
          })
        );
        return "success";
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã xảy ra lỗi!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const canceled = createAsyncThunk(
  "game/canceled",
  async (params: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await TransactionApi.canceled(params);
      const { data, meta } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: "Từ chối yêu cầu thành công!",
          })
        );
        return "success";
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã xảy ra lỗi!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const TransactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(transactions.fulfilled, (state, action) => {
      if (action.payload) {
        state.transactions.meta = action.payload.meta;
        state.transactions.datas = action.payload.datas;
      }
    });
    builder.addCase(accepted.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(staticTransaction.fulfilled, (state, action) => {
      if (action.payload) {
          state.staticTransaction = action.payload;
      }
    });
    builder.addCase(canceled.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
  },
});

export default TransactionSlice;
