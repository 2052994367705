import { unwrapResult } from "@reduxjs/toolkit";
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import UserSlice, { addTransaction } from "stores/UserSlice";
import { formatNumberLocal } from "utils";

type Props = { modalOpen: boolean; setModalOpen: Function; actionType: string };

const ModalWithdraw: FC<Props> = ({ modalOpen, setModalOpen, actionType }) => {
  const dispatch = useAppDispatch();
  const { userSelected } = useAppSelector((state) => state.user);
  const { counterLoading } = useAppSelector((state) => state.common);
  const [form] = Form.useForm();

  useEffect(() => {
    if (userSelected) {
      form.setFieldsValue({
        username: userSelected.username,
        balance: formatNumberLocal(userSelected.userCoin.balance),
        status: "PENDING",
      });
    }
  }, [userSelected]);

  const onCancel = () => {
    dispatch(UserSlice.actions.setUserSelected(null));
    setModalOpen("");
    form.setFieldsValue({
      username: "",
      balance: "",
      amount: "",
      status: "PENDING",
    });
  };

  const onFinish = async (values: any) => {
    const params = {
      userId: userSelected?.id,
      type: actionType,
      amount: values.amount,
      status: values.status,
    };
    const rs = unwrapResult(await dispatch(addTransaction(params)));
    if (rs) {
      onCancel();
    }
  };

  return (
    <Modal
      open={modalOpen}
      footer={null}
      closeIcon={false}
      destroyOnClose
      onCancel={onCancel}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: actionType === "DEPOSIT" ? "#009292" : "#FC8181",
            borderRadius: 5,
          },
        }}
      >
        <Typography.Title
          level={3}
          style={{ color: actionType === "DEPOSIT" ? "#009292" : "#FC8181" }}
        >
          {actionType === "DEPOSIT"
            ? "Nạp tiền"
            : actionType === "WITHDRAW"
            ? "Rút tiền"
            : ""}
        </Typography.Title>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={18}>
            <Col span={12}>
              <Form.Item name="username" label="Tên đăng nhập">
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="amount"
                label="Số tiền (K)"
                rules={[
                  { required: true, message: "Vui lòng nhập số tiền cần nạp!" },
                ]}
              >
                <InputNumber min={100} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="balance" label="Số dư ví (K)">
                <Input disabled />
              </Form.Item>
              <Form.Item name="status" label="Trạng thái">
                <Select
                  options={[
                    { value: "SUCCESSFUL", label: "Đã duyệt" },
                    { value: "PENDING", label: "Chưa duyệt" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Flex justify="center" gap="small">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#C4C4C4",
                  borderRadius: 10,
                },
              }}
            >
              <Button type="primary" onClick={onCancel}>
                <Space>
                  <img src="/images/ic_logout.svg" alt="" /> Trở về
                </Space>
              </Button>
            </ConfigProvider>
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: 10 }}
              loading={counterLoading > 0}
            >
              <Space>
                {
                  actionType === "WITHDRAW" ? <img src="/images/ic_withdraw.svg" alt="" />:
                      actionType === "DEPOSIT" ? <img src="/images/ic_deposit.svg" alt="" />:
                    <img src="/images/ic_edit.svg" alt="" />
                }
                {actionType === "DEPOSIT"
                    ? "Nạp tiền"
                    : actionType === "WITHDRAW"
                        ? "Rút tiền"
                        : ""}
              </Space>
            </Button>
          </Flex>
        </Form>
      </ConfigProvider>
    </Modal>
  );
};

export default ModalWithdraw;
