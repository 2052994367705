import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { formatNumberLocal } from "utils";

export default function TotalPredict() {
  const { detail } = useAppSelector((state) => state.user);
  return (
    <div style={{ background: "#fff", padding: 24, height: "100%" }}>
      <Descriptions column={6} title="Thông tin đặt cược">
        <Descriptions.Item span={6} label="Tổng số tiền cược">
          <Typography.Text strong>
            {formatNumberLocal(detail?.userCoin.totalPredictAmount)}K
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Tổng số thắng cược">
          <Typography.Text strong>
            {formatNumberLocal(detail?.userCoin.totalWinAmount)}K
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Tổng số thua cược">
          <Typography.Text strong>
            {formatNumberLocal(detail?.userCoin.totalLoseAmount)}K
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Lợi nhuận">
          <Typography.Text strong>
            {formatNumberLocal(
              (detail?.userCoin.totalWinAmount || 0) -
                (detail?.userCoin.totalLoseAmount || 0)
            )}
            K
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
