import {
  Button,
  ConfigProvider,
  Dropdown,
  Flex,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import { accepted, canceled } from "stores/TransactionSlice";
import { Helpers, formatNumberLocal } from "utils";
const { Text } = Typography;

type Props = {
  filter: any;
  onChangePage: (page: number) => void;
};

export const textEllipseMiddle = (str: string) => {
  if (str && str.length > 10) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
};

export default function List(props: Props) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const typeParams = searchParams.get("type");
  const datas = useAppSelector((state) => state.transaction).transactions.datas;
  const meta = useAppSelector((state) => state.transaction).transactions.meta;

  const onAcceptRequest = (id: string) => {
    dispatch(accepted(id));
  };

  const onCancelRequest = (id: string) => {
    dispatch(canceled(id));
  };

  const historyColumns = [
    {
      title: "ID",
      dataIndex: "user",
      key: "id",
      render: (value: any) => value.shortId || "",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "user",
      key: "username",
      render: (value: any) => value.username || "",
    },
    {
      title: "Loại giao dịch",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (value: string) => (
        <Typography.Text
          strong
          type={
            value === "DEPOSIT"
              ? "success"
              : value === "WITHDRAW"
              ? "danger"
              : undefined
          }
        >
          {value === "DEPOSIT"
            ? "Nạp tiền"
            : value === "WITHDRAW"
            ? "Rút tiền"
            : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (value: number) => (
        <Typography.Text>{`${formatNumberLocal(value)}K`}</Typography.Text>
      ),
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Typography.Text
          type={
            value === "PENDING"
              ? "warning"
              : value === "SUCCESSFUL"
              ? "success"
              : undefined
          }
          strong
        >
          {value === "PENDING"
            ? "Chờ duyệt"
            : value === "SUCCESSFUL"
            ? "Đã duyệt"
            : "-"}
        </Typography.Text>
      ),
    },
  ];

  const pendingColumns = [
    {
      title: "ID",
      dataIndex: "user",
      key: "id",
      render: (value: any) => value.shortId || "",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "user",
      key: "username",
      render: (value: any) => value.username || "",
    },
    {
      title: `Số tiền ${typeParams === "pending-deposit" ? "Nạp" : "Rút"}`,
      dataIndex: "amount",
      key: "amount",
      render: (value: number) => (
        <Typography.Text strong>{`${formatNumberLocal(
          value
        )}K`}</Typography.Text>
      ),
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Typography.Text
          type={
            value === "PENDING"
              ? "warning"
              : value === "SUCCESSFUL"
              ? "success"
              : undefined
          }
          strong
        >
          {value === "PENDING"
            ? "Chờ duyệt"
            : value === "SUCCESSFUL"
            ? "Đã duyệt"
            : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Thao tác",
      key: "action",
      render: (_: any, record: TransactionType) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#009292",
              borderRadius: 50,
            },
          }}
        >
          <Space>
            <Popconfirm
              title="Duyệt yêu cầu!"
              description="Bạn chắc chắn muốn duyệt yêu cầu này?"
              onConfirm={() => onAcceptRequest(record.id)}
              okText="OK"
              cancelText="Huỷ"
            >
              <Button type="primary">Duyệt</Button>
            </Popconfirm>

            <Popconfirm
              title="Từ chối yêu cầu!"
              description="Bạn chắc chắn muốn từ chối yêu cầu này?"
              onConfirm={() => onCancelRequest(record.id)}
              okText="OK"
              cancelText="Huỷ"
            >
              <Button danger>Không duyệt</Button>
            </Popconfirm>
          </Space>
        </ConfigProvider>
      ),
    },
  ];

  return (
    <div className="table-wrapper">
      <Table
        className="table-custom table-resquest-deposit-withdraw"
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        columns={
          typeParams != "pending-deposit" && typeParams != "pending-withdraw"
            ? historyColumns
            : pendingColumns
        }
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 15,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => props.onChangePage(page),
        }}
      />
    </div>
  );
}
