import { Button, Flex, Popconfirm } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { canceledGame, closeGame, openGame } from "stores/GameSlice";
import SetResultPopup from "./SetResultPopup";
import { unwrapResult } from "@reduxjs/toolkit";

export default function Actions() {
  const dispatch = useAppDispatch();
  const { current } = useAppSelector((state) => state.game);

  const [isSetResult, setIsSetResult] = useState(false);

  const onCloseRound = async () => {
    const result = unwrapResult(await dispatch(closeGame(current.id)));
    if (result) {
      setIsSetResult(true);
    }
  };

  const onOpenRound = () => {
    dispatch(openGame());
  };

  const onCancelRound = () => {
    dispatch(canceledGame(current.id));
  };

  return (
    <>
      <Flex gap="small" wrap="wrap" style={{ marginTop: 16 }}>
        <Button
          disabled={
            current.status === "CANCELED" ||
            current.status === "SETTLED" ||
            !current.id
              ? false
              : true
          }
          type="primary"
          onClick={onOpenRound}
        >
          Bắt đầu đặt cược
        </Button>
        <Button
          disabled={current.status === "OPEN" ? false : true}
          type="primary"
          onClick={onCloseRound}
        >
          Dừng đặt cược
        </Button>
        <Button
          disabled={current.status === "CLOSE" ? false : true}
          type="primary"
          onClick={() => setIsSetResult(true)}
        >
          Nhập kết quả
        </Button>
        <Popconfirm
          title="Huỷ đặt cược!"
          description="Bạn chắc chắn muốn huỷ vòng chơi chứ!"
          onConfirm={onCancelRound}
          okText="OK"
          cancelText="Huỷ"
        >
          <Button
            disabled={
              !current.id
                ? true
                : current.status === "SETTLED" || current.status === "CANCELED"
                ? true
                : false
            }
            danger
            type="primary"
          >
            Huỷ đặt cược
          </Button>
        </Popconfirm>
      </Flex>
      <SetResultPopup
        isOpen={isSetResult}
        onCancel={() => setIsSetResult(false)}
      />
    </>
  );
}
