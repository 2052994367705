import queryString from "query-string";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {useAppSelector} from "../../../stores";
import {Typography} from "antd";

const listTab = [
  {
    key: "pending-deposit",
    title: "Yêu cầu nạp",
    icon: "/images/ic_deposit_black.svg",
  },
  {
    key: "pending-withdraw",
    title: "Yêu cầu rút",
    icon: "/images/ic_withdraw_black.svg",
  },
  {
    key: "histories",
    title: "Lịch sử",
    icon: "/images/ic_paper.svg",
  },
];

const HeaderTab = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {staticTransaction} = useAppSelector((state) => state.transaction);

  return (
    <div className="finances-tab">
      {listTab.map((tab) => (
        <div
          key={tab.key}
          className={`finances-tab-item ${
            searchParams.get("type") === tab.key
              ? "finances-tab-item-active"
              : ""
          }`}
          onClick={() =>
            searchParams.get("type") !== tab.key
              ? navigate({
                  search: queryString.stringify({
                    page: 1,
                    type: tab.key,
                  }),
                })
              : null
          }
        >
          <div className="img flex justify-between">
            <div>{tab.title}</div>
            <img src={tab.icon} alt="" />
          </div>
          <div>
            <Typography.Text>
              {tab.key === "pending-deposit" ? staticTransaction.totalPendingDeposit : tab.key === "pending-withdraw" ? staticTransaction.totalPendingWithdraw : staticTransaction.totalTransaction}{" "}
              { tab.key === "histories"? "giao dịch": "chờ duyệt"}
            </Typography.Text>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeaderTab;
