import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import HeaderTab from './components/HeaderTab';

const ManagePage = () => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get('type') === null) {
			searchParams.set('type', 'config');
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);
	return (
		<div>
			<HeaderTab />
		</div>
	);
};

export default ManagePage;
