import { Radio, Space } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import DepositWithdrawHistories from "./DepositWithdrawHistories";
import PredictHistories from "./PredictHistories";

const menus = [
  { key: "deposit-withdraw", text: "Lịch sử nạp rút" },
  { key: "predict", text: "Lịch sử đặt cược" },
];

export default function Histories() {
  const navigate = useNavigate();
  const params = useParams();
  const [tabSelected, setTabSelected] = useState("deposit-withdraw");

  const onChangeTab = (e: any) => {
    setTabSelected(e.target.value);
    navigate({
      search: queryString.stringify({ type: e.target.value }),
    });
  };

  return (
    <div
      style={{ background: "#fff", padding: 24, height: "100%", marginTop: 16 }}
    >
      <Radio.Group
        value={tabSelected}
        buttonStyle="solid"
        style={{ marginBottom: 15 }}
        onChange={onChangeTab}
      >
        <Space>
          {menus.map((item, index) => (
            <Radio.Button key={index} value={item.key}>
              {item.text}
            </Radio.Button>
          ))}
        </Space>
      </Radio.Group>
      <RenderComponent tabSelected={tabSelected} userId={params.id || ""} />
    </div>
  );
}

const RenderComponent = ({
  tabSelected,
  userId,
}: {
  tabSelected: string;
  userId: string;
}) => {
  if (userId) {
    switch (tabSelected) {
      case "deposit-withdraw":
        return <DepositWithdrawHistories userId={userId} />;
      case "predict":
        return <PredictHistories userId={userId} />;
      default:
        return null;
    }
  }
  return null;
};
