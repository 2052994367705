import {
	Button,
	Col,
	ConfigProvider,
	Flex,
	Form,
	Input,
	Modal,
	Row,
	Space,
	Typography,
} from 'antd';
import React, { FC } from 'react';

type Props = { modalOpen: boolean; setModalOpen: Function };
const validateMessages = {
	required: '${label} là bắt buộc!',
};
const ModalUpdateUser: FC<Props> = ({ modalOpen, setModalOpen }) => {
	const [form] = Form.useForm();

	return (
		<Modal
			open={modalOpen}
			footer={null}
			closeIcon={false}
			destroyOnClose
			onCancel={() => setModalOpen(false)}
			width={734}
		>
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: '#F6AD55',
						borderRadius: 5,
					},
				}}
			>
				<Typography.Title level={3} style={{ color: '#F6AD55' }}>
					Cập nhật
				</Typography.Title>
				<Form
					form={form}
					layout="vertical"
					autoComplete="off"
					validateMessages={validateMessages}
				>
					<Row gutter={18}>
						<Col span={12}>
							<div style={{ color: '#718096', marginBottom: 24 }}>
								Cấu hình người dùng
							</div>
							<Form.Item
								name="username"
								label="Tên đăng nhập"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item name="name" label="Họ và tên" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
							<Form.Item
								name="phone"
								label="Số điện thoại"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item name="email" label="Email" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
							<Form.Item
								name="password"
								label="Đổi mật khẩu"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<div style={{ color: '#718096', marginBottom: 24 }}>Cấu hình</div>
							<Form.Item
								name="level"
								label="Cấp người dùng"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="status"
								label="Trạng thái tài khoản"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Flex justify="center" gap="small">
						<Button type="primary" htmlType="submit" style={{ borderRadius: 10 }}>
							<Space>
								<img src="/images/ic_edit.svg" alt="" />
								Cập nhật
							</Space>
						</Button>
						<ConfigProvider
							theme={{
								token: {
									colorPrimary: '#C4C4C4',
									borderRadius: 10,
								},
							}}
						>
							<Button type="primary" onClick={() => setModalOpen(false)}>
								<Space>
									<img src="/images/ic_logout.svg" alt="" /> Trở về
								</Space>
							</Button>
						</ConfigProvider>
					</Flex>
				</Form>
			</ConfigProvider>
		</Modal>
	);
};

export default ModalUpdateUser;
