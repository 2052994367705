import { Button, Descriptions, Flex, Input, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { updateUser } from "stores/UserSlice";

export default function InfoUser() {
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.user);
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("deactive");

  useEffect(() => {
    if (detail) {
      setPhone(detail.phone || "");
      setStatus(detail.isActive ? "active" : "deactive");
    }
  }, [detail]);

  const onSave = () => {
    if (detail) {
      const params: any = {
        id: detail.id,
        phone,
        isActive: status === "active" ? true : false,
      };
      if (params.phone === detail.phone) {
        //@ts-ignore
        delete params.phone;
      }
      if (password && password.length > 0) {
        params.password = password;
      }
      dispatch(updateUser(params));
    }
  };

  return (
    <div style={{ background: "#fff", padding: 24 }}>
      <Descriptions column={6} title="Thông tin Người dùng">
        <Descriptions.Item span={6} label="Username">
          <Typography.Text strong>{detail?.username}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Mật khẩu">
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Số điện thoại">
          <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Trạng thái">
          <Select
            style={{ width: "100%" }}
            value={status}
            onChange={(value) => setStatus(value)}
            options={[
              { value: "active", label: "Hoạt động" },
              { value: "deactive", label: "Tạm khoá" },
            ]}
          />
        </Descriptions.Item>
      </Descriptions>
      <Flex justify="end">
        <Button onClick={onSave} type="primary" size="large">
          Lưu lại
        </Button>
      </Flex>
    </div>
  );
}
