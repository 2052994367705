import {Button, ConfigProvider, Flex, Input, Space, Typography, Select} from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import FilterContent from "./FilterContent";
import { SearchProps } from "antd/es/input";
import FormSearch from "../../user/components/FormSearch";

type Props = {
  onSearch: (params: any) => void;
  filter: any;
};
const Content = (props: Props) => {
  const [searchParams] = useSearchParams();
  const typeParams = searchParams.get("type");

  const { filter, onSearch } = props;
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("ALL");

  const handleOnSearch: SearchProps["onSearch"] = (value, _e, info) => {
    const params = {
      s: value,
    };
    onSearch(params);
  };

  const handleChange = (value: string) => {
    const params = {
      status: value,
    };
    onSearch(params);
    setStatus(value);
  };

  useEffect(() => {
    if (filter && filter.s) {
      setSearchValue(filter.s);
    } else {
      setSearchValue("");
    }

    if (filter && filter.status) {
      setStatus(filter.status);
    } else {
      setStatus("ALL");
    }
  }, [filter]);

  return (
    <div>
      <Flex className="mobile-flex-col" justify="space-between" style={{ marginBottom: 16 }}>
        <div className="content-title">
          {typeParams === "pending-deposit"
              ? "Yêu cầu nạp"
              : typeParams === "pending-withdraw"
                  ? "Yêu cầu rút"
                  : "Lịch sử giao dịch"}
        </div>
        <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#009292",
                borderRadius: 10,
              },
            }}
        >
          <Flex>
            {
              typeParams === "histories" &&
                <Select
                    value={status}
                    style={{ width: 250, marginRight: 10 }}
                    onChange={handleChange}
                    options={[
                      { value: 'ALL', label: 'Tất cả' },
                      { value: 'SUCCESSFUL', label: 'Đã duyệt' },
                      { value: 'PENDING', label: 'Chờ duyệt' },
                      { value: 'CANCELED', label: 'Đã hủy'},
                    ]}
                />
            }
            <Input.Search
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Tài khoản, email, số điện thoại..."
                onSearch={handleOnSearch}
                enterButton
                allowClear
            />
          </Flex>
        </ConfigProvider>
      </Flex>
    </div>
  );
};

export default Content;
