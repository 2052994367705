import { Card, Col, Flex, Row, Typography } from "antd";
import {
  UserAddOutlined,
  WalletOutlined,
  CrownOutlined,
  RocketOutlined,
  DeleteColumnOutlined,
  SolutionOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import React from "react";
import { useAppSelector } from "../../../stores";

const General = () => {
  const { general, transaction } = useAppSelector((state) => state.general);

  return (
    <div className={"mt-10"}>
      <Row
        gutter={[
          { xs: 0, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
          { xs: 12, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
        ]}
      >
        <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Card style={{ width: "100%" }}>
            <div className="flex justify-between" style={{ color: "#718096" }}>
              Tổng số tiền đặt cược{" "}
              <SolutionOutlined style={{ color: "#718096", fontSize: 25 }} />
            </div>
            <div
              style={{
                marginTop: 16,
                color: "#2C5282",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {general.totalPredictAmount.toLocaleString("vi-VN")}K
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Card style={{ width: "100%" }}>
            <div className="flex justify-between" style={{ color: "#718096" }}>
              Tổng số người dùng mới{" "}
              <UserAddOutlined style={{ fontSize: 25, color: "#718096" }} />
            </div>
            <div
              style={{
                marginTop: 16,
                color: "#2C5282",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {general.totalUser.toLocaleString("vi-VN")}
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Card style={{ width: "100%" }}>
            <div className="flex justify-between" style={{ color: "#718096" }}>
              Tổng số dư ví{" "}
              <WalletOutlined style={{ fontSize: 25, color: "#718096" }} />
            </div>
            <div
              style={{
                marginTop: 16,
                color: "#2C5282",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {general.totalBalance.toLocaleString("vi-VN")}K
            </div>
          </Card>
        </Col>
      </Row>

      <div className={"mt-10"}>
        <Row
          gutter={[
            { xs: 0, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
            { xs: 12, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
          ]}
        >
          <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Card style={{ width: "100%" }}>
              <div
                className="flex justify-between"
                style={{ color: "#718096" }}
              >
                Tổng số thắng cược{" "}
                <CrownOutlined style={{ fontSize: 25, color: "#718096" }} />
              </div>
              <div
                style={{
                  marginTop: 16,
                  color: "#2C5282",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {general.totalWinAmount.toLocaleString("vi-VN")}K
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Card style={{ width: "100%" }}>
              <div
                className="flex justify-between"
                style={{ color: "#718096" }}
              >
                Tổng số thua cược{" "}
                <DeleteColumnOutlined
                  style={{ fontSize: 25, color: "#718096" }}
                />
              </div>
              <div
                style={{
                  marginTop: 16,
                  color: "#2C5282",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {general.totalLoseAmount.toLocaleString("vi-VN")}K
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Card style={{ width: "100%" }}>
              <div
                className="flex justify-between"
                style={{ color: "#718096" }}
              >
                Tổng hoa hồng{" "}
                <RocketOutlined style={{ fontSize: 25, color: "#718096" }} />
              </div>
              <div
                style={{
                  marginTop: 16,
                  color: "#2C5282",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {(
                  Math.floor(general.totalFee)
                ).toLocaleString("vi-VN")}
                K
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="mt-10">
        <Row
          gutter={[
            { xs: 0, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
            { xs: 12, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
          ]}
        >
          <Col span={8} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card style={{ width: "100%" }}>
              <div
                className="flex justify-between"
                style={{ color: "#718096" }}
              >
                <Typography.Title level={3}>Nạp tiền</Typography.Title>
                <PlusCircleOutlined
                  style={{ color: "#718096", fontSize: 25 }}
                />
              </div>
              <div style={{ marginTop: 16, fontSize: 20, fontWeight: 500 }}>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền nạp:{" "}
                    {transaction.totalDeposit.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền nạp đã duyệt:{" "}
                    {transaction.totalDeposit.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền nạp chờ duyệt:{" "}
                    {transaction.totalPendingDeposit.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền nạp đã hủy:{" "}
                    {transaction.totalCancelDeposit.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card style={{ width: "100%" }}>
              <div
                className="flex justify-between"
                style={{ color: "#718096" }}
              >
                <Typography.Title level={3}>Rút tiền</Typography.Title>{" "}
                <MinusCircleOutlined
                  style={{ fontSize: 25, color: "#718096" }}
                />
              </div>
              <div style={{ marginTop: 16, fontSize: 20, fontWeight: 500 }}>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền rút:{" "}
                    {transaction.totalWithdraw.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền rút đã duyệt:{" "}
                    {transaction.totalWithdraw.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền rút chờ duyệt:{" "}
                    {transaction.totalPendingWithdraw.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
                <div className={"flex"}>
                  <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography.Text>
                    {" "}
                    Tổng số tiền rút đã hủy:{" "}
                    {transaction.totalCancelWithdraw.toLocaleString("vi-VN")}K
                  </Typography.Text>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {/* <div className="home-overview mt-10">
        <Card style={{ width: "50%" }}>
          <div className="flex justify-between" style={{ color: "#718096" }}>
            <Typography.Title level={3}>Nạp tiền</Typography.Title>
            <PlusCircleOutlined style={{ color: "#718096", fontSize: 25 }} />
          </div>
          <div style={{ marginTop: 16, fontSize: 20, fontWeight: 500 }}>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền nạp:{" "}
                {transaction.totalDeposit.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền nạp đã duyệt:{" "}
                {transaction.totalDeposit.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền nạp chờ duyệt:{" "}
                {transaction.totalPendingDeposit.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền nạp đã hủy:{" "}
                {transaction.totalCancelDeposit.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
          </div>
        </Card>
        <Card style={{ width: "50%" }}>
          <div className="flex justify-between" style={{ color: "#718096" }}>
            <Typography.Title level={3}>Rút tiền</Typography.Title>{" "}
            <MinusCircleOutlined style={{ fontSize: 25, color: "#718096" }} />
          </div>
          <div style={{ marginTop: 16, fontSize: 20, fontWeight: 500 }}>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền rút:{" "}
                {transaction.totalWithdraw.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền rút đã duyệt:{" "}
                {transaction.totalWithdraw.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền rút chờ duyệt:{" "}
                {transaction.totalPendingWithdraw.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
            <div className={"flex"}>
              <CaretRightFilled style={{ fontSize: 15, marginRight: 5 }} />
              <Typography.Text>
                {" "}
                Tổng số tiền rút đã hủy:{" "}
                {transaction.totalCancelWithdraw.toLocaleString("vi-VN")}K
              </Typography.Text>
            </div>
          </div>
        </Card>
      </div> */}
    </div>
  );
};

export default General;
