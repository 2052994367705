import {Card, Typography} from 'antd';
import React from 'react';
import {
	ResponsiveContainer,
	BarChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Rectangle
} from 'recharts';
import {useAppSelector} from "../../../stores";

const Chart = () => {
	const {dailyTransactions} = useAppSelector((state) => state.general);

	return (
			<Card style={{ width: '100%', marginTop: 14, minHeight: 500 }}>
				<div className="" style={{ color: '#718096' }}>
					<Typography.Title level={3}>Tổng số nạp rút hàng ngày</Typography.Title>
				</div>
				<ResponsiveContainer height={500}>
					<BarChart
						width={500}
						height={400}
						data={dailyTransactions.map((item: any) => {
							return {
								date: item._id,
								"Tổng tiền nạp": item.totalDepositVolume,
								"Tổng tiền rút": item.totalWithdrawVolume
							}
						})}
						margin={{
							top: 20,
							right: 20,
							bottom: 20,
							left: 20,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar dataKey="Tổng tiền nạp" fill="#8884d8" label={"Total Withdraw"} activeBar={<Rectangle fill="pink" stroke="blue" />} />
						<Bar dataKey="Tổng tiền rút" fill="#82ca9d" label={"Total Deposit"} activeBar={<Rectangle fill="gold" stroke="purple" />} />
					</BarChart>
				</ResponsiveContainer>
			</Card>
	)
};

export default Chart;
