import { unwrapResult } from "@reduxjs/toolkit";
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { addUser } from "stores/UserSlice";

type Props = { modalOpen: boolean; setModalOpen: Function };

const validateMessages = {
  required: "${label} là bắt buộc!",
};
const ModalAddUser: FC<Props> = ({ modalOpen, setModalOpen }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { counterLoading } = useAppSelector((state) => state.common);

  useEffect(() => {
    form.setFieldsValue({ phone: "", status: "deactive" });
  }, []);

  const onFinish = async (values: any) => {
    const params = {
      password: values.password,
      username: values.username,
      phone: values.phone,
      isActive: values.status === "active" ? true : false,
    };
    const rs = unwrapResult(await dispatch(addUser(params)));
    if (rs) {
      onCancel();
    }
  };

  const onCancel = () => {
    setModalOpen(false);
    form.setFieldsValue({
      username: "",
      password: "",
      phone: "",
      status: "deactive",
    });
  };

  return (
    <Modal
      open={modalOpen}
      footer={null}
      closeIcon={false}
      destroyOnClose
      onCancel={onCancel}
      //   width={734}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#F6AD55",
            borderRadius: 5,
          },
        }}
      >
        <Typography.Title level={3} style={{ color: "#F6AD55" }}>
          Thêm khách hàng
        </Typography.Title>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <Form.Item
            name="username"
            label="Tên đăng nhập"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mật khẩu"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Số điện thoại">
            <Input />
          </Form.Item>

          <Form.Item name="status" label="Trạng thái tài khoản">
            <Select
              options={[
                { value: "active", label: "Hoạt động" },
                { value: "deactive", label: "Tạm khoá" },
              ]}
            />
          </Form.Item>

          <Flex justify="flex-end" gap="small">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#C4C4C4",
                  borderRadius: 10,
                },
              }}
            >
              <Button type="primary" onClick={onCancel}>
                <Space>
                  <img src="/images/ic_logout.svg" alt="" /> Trở về
                </Space>
              </Button>
            </ConfigProvider>
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: 10 }}
              loading={counterLoading > 0}
            >
              <Space>
                <img src="/images/ic_edit.svg" alt="" />
                Thêm khách hàng
              </Space>
            </Button>
          </Flex>
        </Form>
      </ConfigProvider>
    </Modal>
  );
};

export default ModalAddUser;
