import { RequestManager } from "utils";
import config from "configs";

const templates = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/templates`
  );
};

const detailTemplate = (templateId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/templates/${templateId}`
  );
};

const updatelTemplate = (params: any) => {
  const { id, ...ortherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/templates/${id}`,
    ortherParams
  );
};

const exported = {
  templates,
  detailTemplate,
  updatelTemplate,
};

export default exported;
