import { RequestManager } from "utils";
import config from "configs";

const getUsers = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users`,
    params
  );
};

const addUser = (params: any) => {
  return RequestManager.v1.withAuthorize.post(
    `${config.api_base_url}/admin/users`,
    params
  );
};

const addTransaction = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.post(
    `${config.api_base_url}/admin/users/${userId}/transactions`,
    otherParams
  );
};

const detailUser = (userId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}`
  );
};

const updateUser = (params: any) => {
  const { id, ...ortherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/users/${id}`,
    ortherParams
  );
};

const userTransactions = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/transactions`,
    otherParams
  );
};

const userPredicts = (params: any) => {
  const { userId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/users/${userId}/predicts`,
    otherParams
  );
};

const exported = {
  getUsers,
  addUser,
  addTransaction,
  detailUser,
  updateUser,
  userTransactions,
  userPredicts,
};

export default exported;
