import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import queryString from "query-string";
import Helpers from "utils/Helpers";
import { userTransactions } from "stores/UserSlice";
import { Table, Typography } from "antd";
import moment from "moment";
import { formatNumberLocal } from "utils";

type IProps = {
  userId?: string;
};

export const textEllipseMiddle = (str: string) => {
  if (str && str.length > 10) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
};

export default function DepositWithdrawHistories(props: IProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<any>(null);

  const datas = useAppSelector((state) => state.user).userTransactions.datas;
  const meta = useAppSelector((state) => state.user).userTransactions.meta;

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 10,
    };
  }, [location.search]);

  function fetchData() {
    //@ts-ignore
    const params = { ...filter };
    if (filter) {
      params.userId = props.userId;
      dispatch(userTransactions(params));
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, props.userId]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({ ...Helpers.clean(query) });
    }
  }, [query]);

  const columns: any = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (value: string) => textEllipseMiddle(value),
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Loại giao dịch",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (value: string) => (
        <Typography.Text
          strong
          type={
            value === "DEPOSIT"
              ? "success"
              : value === "WITHDRAW"
              ? "danger"
              : undefined
          }
        >
          {value === "DEPOSIT"
            ? "Nạp tiền"
            : value === "WITHDRAW"
            ? "Rút tiền"
            : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (value: number) => (
        <Typography.Text strong>{`${formatNumberLocal(
          value
        )}K`}</Typography.Text>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Typography.Text
          type={
            value === "PENDING"
              ? "warning"
              : value === "SUCCESSFUL"
              ? "success"
              : undefined
          }
          strong
        >
          {value === "PENDING"
            ? "Chờ duyệt"
            : value === "SUCCESSFUL"
            ? "Đã duyệt"
            : "-"}
        </Typography.Text>
      ),
    },
  ];

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({
        ...query,
        type: "deposit-withdraw",
        page,
      }),
    });
  }

  return (
    <div className="table-wrapper">
      <Table
        className="table-custom table-deposit-withdraw-history"
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        columns={columns}
        pagination={{
          defaultPageSize: filter ? filter.pageSize : 10,
          defaultCurrent: filter ? filter.page : 1,
          current: filter ? filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => onChangePage(page),
        }}
      />
    </div>
  );
}
