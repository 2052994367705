import { unwrapResult } from "@reduxjs/toolkit";
import { InputNumber, Modal, Space } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import CommonSlice from "stores/CommonSlice";
import { settledGame } from "stores/GameSlice";

type IProps = {
  isOpen: boolean;
  onCancel: () => void;
};

export default function SetResultPopup(props: IProps) {
  const dispatch = useAppDispatch();
  const { isOpen, onCancel } = props;

  const { current } = useAppSelector((state) => state.game);

  const [result, setResult] = useState([0, 0, 0]);

  const onChangeResult = (value: any, index: number) => {
    const arr = [...result];
    arr[index] = value;
    setResult(arr);
  };

  const onSettledGame = async () => {
    const rs = unwrapResult(
      await dispatch(settledGame({ roundId: current.id, result: result }))
    );
    if (rs) {
      onCancel();
      setResult([0, 0, 0]);
    }
  };

  const onSubmit = () => {
    const isValid = result.find((item) => item === 0) === 0 ? false : true;

    if (isValid) {
      onSettledGame();
    } else {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "warning",
          message: "Thông báo!",
          description: "Vui lòng nhập đầy đủ kết quả",
        })
      );
    }
  };

  return (
    <Modal
      title="Nhập kết quả"
      open={isOpen}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Space wrap>
        <InputNumber
          min={1}
          max={6}
          value={result[0]}
          onChange={(value) => onChangeResult(value, 0)}
        />
        <InputNumber
          min={1}
          max={6}
          value={result[1]}
          onChange={(value) => onChangeResult(value, 1)}
        />
        <InputNumber
          min={1}
          max={6}
          value={result[2]}
          onChange={(value) => onChangeResult(value, 2)}
        />
      </Space>
    </Modal>
  );
}
