import { DollarOutlined, SolutionOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, InputNumber, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { settings as fetch, updateSetting } from "stores/SettingSlice";

export default function Setting() {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state) => state.setting);
  const { token } = useAppSelector((state) => state.profile);
  const [fee, setFee] = useState<any>(0);
  const [diffLimit, setDiffLimit] = useState<any>(0);

  const onUpdateFee = () => {
    const params = {
      key: "fee",
      value: fee / 100,
    };
    dispatch(updateSetting(params));
  };

  const onUpdateDiffLimit = () => {
    const params = {
      key: "higher_lower_diff_limit",
      value: diffLimit,
    };
    dispatch(updateSetting(params));
  };

  useEffect(() => {
    if (settings.length) {
      const settingFee = settings.find((item) => item.key === "fee");
      const settingDiffLimit = settings.find((item) => item.key === "higher_lower_diff_limit");
      if (settingFee) {
        setFee(settingFee.value * 100);
      }
      if (settingDiffLimit) {
        setDiffLimit(settingDiffLimit.value as number)
      }
    }
  }, [settings]);

  useEffect(() => {
    if (token) dispatch(fetch());
  }, [token]);

  return (
    <div className={"home-container"}>
      <Flex className="cus-page-header" justify="space-between">
        <Typography.Title level={2}>Cài đặt</Typography.Title>
      </Flex>
      <div className={"mt-10"}>
        <Row
          gutter={[
            { xs: 0, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
            { xs: 12, sm: 20, md: 20, lg: 20, xl: 20, xxl: 20 },
          ]}
        >
          <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Card style={{ width: "100%" }}>
              <div
                className="flex justify-between"
                style={{ color: "#718096" }}
              >
                <Typography.Text strong>Phí Thắng</Typography.Text>
                <DollarOutlined style={{ color: "#718096", fontSize: 25 }} />
              </div>
              <div
                style={{
                  marginTop: 16,
                }}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  value={fee}
                  onChange={(value) => setFee(value)}
                  addonAfter="%"
                />
              </div>
              <Flex style={{ marginTop: 10 }} justify="end">
                <Button
                  disabled={fee === null}
                  onClick={onUpdateFee}
                  type="primary"
                >
                  Lưu
                </Button>
              </Flex>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Card style={{ width: "100%" }}>
              <div
                  className="flex justify-between"
                  style={{ color: "#718096" }}
              >
                <Typography.Text strong>Giới hạn chênh lệch Tài - Xỉu </Typography.Text>
                <DollarOutlined style={{ color: "#718096", fontSize: 25 }} />
              </div>
              <div
                  style={{
                    marginTop: 16,
                  }}
              >
                <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    value={diffLimit}
                    onChange={(value) => setDiffLimit(value)}
                    addonAfter="K"
                />
              </div>
              <Flex style={{ marginTop: 10 }} justify="end">
                <Button
                    disabled={diffLimit === null}
                    onClick={onUpdateDiffLimit}
                    type="primary"
                >
                  Lưu
                </Button>
              </Flex>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
