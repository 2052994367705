import { Button, ConfigProvider, Table } from "antd";
import { textEllipseMiddle } from "pages/finances/components/List";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "stores";
import { formatNumberLocal } from "utils";

export default function List() {
  const navigate = useNavigate();
  const { templates } = useAppSelector((state) => state.template);

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (value: string) => textEllipseMiddle(value),
    },
    {
      title: "Tên loại cược",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tối thiểu cược",
      dataIndex: "min",
      key: "min",
      render: (value: number) => `${formatNumberLocal(value)}K`,
    },
    {
      title: "Hạn mức cược",
      dataIndex: "limit",
      key: "limit",
      render: (value: number) => `${formatNumberLocal(value)}K`,
    },
    {
      title: "Thao tác",
      key: "action",
      render: (_: any, record: TemplateType) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#009292",
              borderRadius: 50,
            },
          }}
        >
          <Button
            onClick={() => navigate(`/template/${record.id}`)}
            type="primary"
          >
            Chi tiết / Cấu hình
          </Button>
        </ConfigProvider>
      ),
    },
  ];
  return (
    <div className="table-wrapper">
      <Table
        className="table-custom table-template"
        dataSource={templates.map((item) => ({ key: item.id, ...item }))}
        columns={columns}
        pagination={false}
      />
    </div>
  );
}
