import { Button, Col, ConfigProvider, Flex, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import { detailUser } from "stores/UserSlice";
import InfoUser from "./components/InfoUser";
import Balance from "./components/Balance";
import TotalPredict from "./components/TotalPredict";
import Histories from "./components/Histories";

export default function Detail() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const accessToken = useAppSelector((state) => state.profile).token;

  const { detail } = useAppSelector((state) => state.user);

  const fetchData = () => {
    if (id && accessToken) {
      dispatch(detailUser(id));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, accessToken]);

  return (
    <div className={"home-container"}>
      <Flex justify="space-between" className="items-center">
        <Typography.Title level={4}>
          <span className="mobile-hidden">Chi tiết người dùng:</span>{" "}
          {detail?.username}
        </Typography.Title>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#FC8181",
              borderRadius: 10,
            },
          }}
        >
          <Button onClick={() => navigate("/user")}>Quay lại</Button>
        </ConfigProvider>
      </Flex>
      <Row
        gutter={[
          { xs: 0, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 },
          { xs: 10, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 },
        ]}
        className="mt-10"
      >
        <Col span={8} xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <InfoUser />
        </Col>
        <Col span={8} xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Balance />
        </Col>
        <Col span={8} xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <TotalPredict />
        </Col>
      </Row>
      <Histories />
    </div>
  );
}
