import React, { useEffect, useMemo, useState } from "react";
import HeaderTab from "./components/HeaderTab";
import { useAppDispatch, useAppSelector } from "stores";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { Helpers } from "utils";
import Content from "./components/Content";
import List from "./components/List";
import TransactionSlice, {staticTransaction, transactions} from "stores/TransactionSlice";

const FinancesPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { token } = useAppSelector((state) => state.profile);
  const { isRefresh } = useAppSelector((state) => state.transaction);
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 15,
      type: data.type ? data.type : "pending-deposit",
      s: data.s ? data.s : null,
      status: data.status ? data.status : "ALL"
    };
  }, [location.search]);

  const onFetchData = () => {
    if (filter) {
      let params = { ...filter };
      delete params.type;
      if (filter.type === "pending-deposit") {
        params.status = "PENDING";
        params.transactionType = "DEPOSIT";
      } else if (filter.type === "pending-withdraw") {
        params.status = "PENDING";
        params.transactionType = "WITHDRAW";
      } else {
        delete params.transactionType;
      }
      if (params.status === "ALL") {
        delete params.status;
      }
      dispatch(transactions(params));
    }
  };

  useEffect(() => {
    if (filter && token) {
      onFetchData();
    }
  }, [filter, token]);

  useEffect(() => {
    if (isRefresh) {
      onFetchData();
      dispatch(TransactionSlice.actions.setIsRefresh(false));
    }
  }, [isRefresh]);

  const onSearch = (params: any) => {
    const filterValue = { ...query, ...params };

    if (params.s === "") {
      delete filterValue.s;
    }

    navigate({
      search: queryString.stringify({ ...filterValue }),
    });
  };

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({ ...query, page }),
    });
  }

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
  }, [query]);

  useEffect(() => {
    if (searchParams.get("type") === null) {
      searchParams.set("type", "pending-deposit");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (token) {
      dispatch(staticTransaction({}));
    }
  }, [token]);

  return (
    <div className="finances home-container">
      <HeaderTab />
      <div className="finances-content">
        <Content filter={filter} onSearch={onSearch} />
        <List filter={filter} onChangePage={onChangePage} />
      </div>
    </div>
  );
};

export default FinancesPage;
