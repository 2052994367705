import MainRouter from 'routers/MainRouter';
import 'antd/dist/reset.css';
import './styles/styles.scss';
import { useAppDispatch, useAppSelector } from 'stores';
import { useContext, useEffect } from 'react';
import { notification, Spin } from 'antd';
import CommonSlice from 'stores/CommonSlice';
import ProfileSlice from 'stores/ProfileSlice';
import request from 'utils/RequestManager/RequestGlobalConfig';

function App() {
	const dispatch = useAppDispatch();
	const { counterLoading, type, message, description } = useAppSelector((state) => state.common);
	const { token } = useAppSelector((state) => state.profile);

	const getAccessToken = async (): Promise<any> => {
		const AccessToken = await localStorage.getItem('AccessToken');
		return AccessToken;
	};

	const fetchCredentials = async () => {
		let accessTokenInLocalStorage = await getAccessToken();
		try {
			if (accessTokenInLocalStorage) {
				dispatch(ProfileSlice.actions.setToken(accessTokenInLocalStorage));
				request.defaults.headers.common['Authorization'] =
					'Bearer ' + accessTokenInLocalStorage;
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		request.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response.status === 401) {
					localStorage.removeItem('AccessToken');
					window.location.href = '/login';
				}
				return error;
			}
		);
	}, []);

	const OpenNotification = () => {
		if (message && type) {
			notification[type]({
				message: message,
				description: description,
				onClose: () => {
					dispatch(
						CommonSlice.actions.showNotice({
							type: null,
							message: '',
							description: '',
						})
					);
				},
			});
		}
	};

	useEffect(() => {
		OpenNotification();
		// eslint-disable-next-line
	}, [type, message]);

	useEffect(() => {
		fetchCredentials();
		// eslint-disable-next-line
	}, []);
	return (
		<div className="App">
			<MainRouter />
		</div>
	);
}

export default App;
