import { RequestManager } from "utils";
import config from "configs";

const gameCurrent = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/games/current`
  );
};

const openGame = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/games/open`
  );
};

const closeGame = (roundId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/games/${roundId}/closed`
  );
};

const settledGame = (params: any) => {
  const { roundId, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.post(
    `${config.api_base_url}/admin/games/${roundId}/settled`,
    otherParams
  );
};

const canceledGame = (roundId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/games/${roundId}/canceled`
  );
};

const exported = {
  gameCurrent,
  openGame,
  closeGame,
  settledGame,
  canceledGame,
};

export default exported;
