import { Flex, Typography } from "antd";
import React, { useEffect } from "react";
import Actions from "./components/Actions";
import { useAppDispatch, useAppSelector } from "stores";
import GameSlice, { gameCurrent } from "stores/GameSlice";
import LiveStream from "./components/LiveStream";

export default function Control() {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.profile);
  const { isRefresh } = useAppSelector((state) => state.game);

  useEffect(() => {
    if (token) dispatch(gameCurrent());
  }, [token]);

  useEffect(() => {
    if (isRefresh) {
      dispatch(gameCurrent());
      dispatch(GameSlice.actions.setIsRefresh(false));
    }
  }, [isRefresh]);

  return (
    <div className={"home-container"}>
      <Flex justify="" style={{ marginBottom: 16 }}>
        <Typography.Title level={4}>Điểu khiển</Typography.Title>
      </Flex>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LiveStream />
        <Actions />
      </div>
    </div>
  );
}
