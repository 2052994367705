import queryString from 'query-string';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const listTab = [
	{
		key: 'config',
		title: 'Cấu hình vòng chơi',
		icon: '/images/ic_category.svg',
	},
	{
		key: 'setting',
		title: 'Cài đặt',
		icon: '/images/ic_setting.svg',
	},
];

const HeaderTab = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const pageSize = searchParams.get('pageSize');

	return (
		<div className="finances-tab">
			{listTab.map((tab) => (
				<div
					key={tab.key}
					className={`finances-tab-item ${
						searchParams.get('type') === tab.key ? 'finances-tab-item-active' : ''
					}`}
					onClick={() =>
						searchParams.get('type') !== tab.key
							? navigate({
									search: queryString.stringify({
										type: tab.key,
									}),
							  })
							: null
					}
				>
					<div className="img">
						<img src={tab.icon} alt="" />
					</div>
					<div>{tab.title}</div>
				</div>
			))}
		</div>
	);
};

export default HeaderTab;
