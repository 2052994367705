import { ConfigProvider, Flex, Typography } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { templates } from "stores/TemplateSlice";
import List from "./components/List";

export default function Template() {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (token) {
      dispatch(templates());
    }
  }, [token]);

  return (
    <div className={"home-container"}>
      <Flex justify="space-between" style={{ marginBottom: 16 }}>
        <Typography.Title level={4}>Danh sách loại cược</Typography.Title>
        <Flex justify="flex-end" gap="small" className="items-center">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#FC8181",
                borderRadius: 10,
              },
            }}
          ></ConfigProvider>
        </Flex>
      </Flex>
      <List />
    </div>
  );
}
