import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../stores";
import CommonSlice from "./CommonSlice";
import GameApi from "apis/GameApi";

const initialState = {
  loading: false as boolean,
  current: {
    createdAt: "",
    createdBy: "",
    id: "",
    isCanceled: false,
    isCompleted: false,
    isOpen: false,
    openAt: "",
    result: [] as number[],
    roundSelections: [] as RoundSelectionType[],
    settledAt: "",
    settledBy: "",
    shortId: 0,
    status: "",
    totalPredictAmount: 0,
    updatedAt: "",
  } as GameCurrentType,
  isRefresh: false,
};

export const gameCurrent = createAsyncThunk(
  "game/gameCurrent",
  async (_, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await GameApi.gameCurrent();
      const { data, meta } = response;
      if (data) {
        return data.data as GameCurrentType;
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const openGame = createAsyncThunk(
  "game/openGame",
  async (_, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await GameApi.openGame();
      const { data, meta } = response;
      if (data) {
        return data.data;
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const closeGame = createAsyncThunk(
  "game/closeGame",
  async (params: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await GameApi.closeGame(params);
      const { data, meta } = response;
      if (data) {
        return "close success!";
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const settledGame = createAsyncThunk(
  "game/settledGame",
  async (params: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await GameApi.settledGame(params);
      const { data, meta } = response;
      if (data) {
        return "settled success!";
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const canceledGame = createAsyncThunk(
  "game/canceledGame",
  async (params: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response: any = await GameApi.canceledGame(params);
      const { data, meta } = response;
      if (data) {
        return "canceled success!";
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const GameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gameCurrent.fulfilled, (state, action) => {
      if (action.payload) {
        state.current = action.payload;
      }
    });
    builder.addCase(closeGame.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(settledGame.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(openGame.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(canceledGame.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
  },
});

export default GameSlice;
