import {
  Button,
  ConfigProvider,
  Dropdown,
  Flex,
  Popover,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import List from "./components/List";
import { Helpers } from "utils";
import { useAppDispatch, useAppSelector } from "stores";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import FormSearch from "./components/FormSearch";
import ListFilter from "./components/ListFilter";
import ModalAddUser from "./components/ModalAddUser";
import UserSlice, { getUsers } from "stores/UserSlice";

const UserPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);
  const [modalOpenAddUser, setModalOpenAddUser] = useState(false);

  const { token } = useAppSelector((state) => state.profile);
  const { isRefresh } = useAppSelector((state) => state.user);

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 15,
      s: data.s ? data.s : null,
    };
  }, [location.search]);

  function onGetListUser() {
    if (filter) {
      dispatch(getUsers(filter));
    }
  }

  useEffect(() => {
    if (token) {
      onGetListUser();
    }
  }, [filter, token]);

  useEffect(() => {
    if (isRefresh) {
      onGetListUser();
      dispatch(UserSlice.actions.setIsRefresh(false));
    }
  }, [isRefresh, filter]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
  }, [query]);

  const onSearch = (params: any) => {
    const filterValue = { ...query, ...params };

    if (params.s === "") {
      delete filterValue.s;
    }

    navigate({
      search: queryString.stringify({ ...filterValue }),
    });
  };

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({ ...query, page }),
    });
  }

  return (
    <div className={"home-container"}>
      <Flex
        className="cus-page-header"
        justify="space-between"
        style={{ marginBottom: 16 }}
      >
        <Typography.Title level={3}>Danh sách người dùng</Typography.Title>
        <Flex justify="flex-end" gap="small" className="items-center">
          <FormSearch filter={filter} onSearch={onSearch} />
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#FC8181",
                borderRadius: 10,
              },
            }}
          >
            <Button type="primary" onClick={() => setModalOpenAddUser(true)}>
              <img src="/images/ic_adduser.svg" alt="" />
              Thêm
            </Button>
          </ConfigProvider>
        </Flex>
      </Flex>
      {/* <ListFilter filter={filter} onSearch={onSearch} /> */}
      <List filter={filter} onChangePage={onChangePage} />
      <ModalAddUser
        modalOpen={modalOpenAddUser}
        setModalOpen={setModalOpenAddUser}
      />
    </div>
  );
};

export default UserPage;
