import { Button, Col, ConfigProvider, Input, Popover, Row, Space } from "antd";
import { useEffect, useState } from "react";
import FilterContent from "./FilterContent";
import { SearchProps } from "antd/es/input";
type Props = {
  onSearch: (params: any) => void;
  filter: any;
};

export default function FormSearch(props: Props) {
  const { filter, onSearch } = props;

  const [searchValue, setSearchValue] = useState("");

  const handleOnSearch: SearchProps["onSearch"] = (value, _e, info) => {
    const params = {
      s: value,
    };
    onSearch(params);
  };

  useEffect(() => {
    if (filter && filter.s) {
      setSearchValue(filter.s);
    }
  }, [filter]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#009292",
          borderRadius: 10,
        },
      }}
    >
      <Input.Search
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Tài khoản, email, số điện thoại..."
        onSearch={handleOnSearch}
        enterButton
		allowClear
      />
    </ConfigProvider>
  );
}
