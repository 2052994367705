import React, { useState, useEffect, useMemo } from "react";
import Chart from "./components/Chart";
import General from "./components/General";
import queryString from "query-string";
import { Helpers } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../stores";
import {
  getDailyTransactionData,
  getGeneralData,
  getTransactionData,
} from "../../stores/GeneralSlice";
import Filter from "./components/Filter";
import moment from "moment";
import { Flex, Typography } from "antd";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [filter, setFilter] = useState(null);

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      from: data.from
        ? data.from
        : moment().subtract(1, "months").format("YYYY-MM-DD"),
      to: data.to ? data.to : moment().format("YYYY-MM-DD"),
    };
  }, [location.search]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({
        ...Helpers.clean(query),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (filter) {
      dispatch(getGeneralData(filter));
      dispatch(getTransactionData(filter));
      dispatch(getDailyTransactionData(filter));
    }
  }, [filter]);

  const onSearch = (params: any) => {
    const filterValue = { ...query, ...params };
    navigate({
      search: queryString.stringify({ ...filterValue }),
    });
  };

  return (
    <div className={"home-container"}>
      <Flex className="cus-page-header" justify="space-between">
        <Typography.Title level={2}>Tổng quan</Typography.Title>
        <Filter filter={filter} onSearch={onSearch} />
      </Flex>
      <General />
      <Chart />
    </div>
  );
};

export default Home;
