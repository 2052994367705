import { Button, Col, ConfigProvider, Flex, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import TemplateSlice, {
  detailTemplate,
  updatelTemplate,
} from "stores/TemplateSlice";
import InfoTemplate from "./components/InfoTemplate";
import Selections from "./components/Selections";

export default function Detail() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const accessToken = useAppSelector((state) => state.profile).token;

  const { detail, isRefresh } = useAppSelector((state) => state.template);

  const fetchData = () => {
    if (id && accessToken) {
      dispatch(detailTemplate(id));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, accessToken]);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      dispatch(TemplateSlice.actions.setIsRefresh(false));
    }
  }, [isRefresh]);

  const onSave = () => {
    dispatch(updatelTemplate(detail));
  };
  return (
    <div className={"home-container"}>
      <Flex className="mobile-flex-col" justify="space-between" style={{ marginBottom: 16 }}>
        <Typography.Title level={4}>Loại cược: {detail?.name}</Typography.Title>
        <Flex justify="flex-end" gap="small" className="items-center">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#009292",
                borderRadius: 10,
              },
            }}
          >
            <Button onClick={onSave} type="primary">
              Lưu lại
            </Button>
            <Button onClick={() => navigate("/template")}>Quay lại</Button>
          </ConfigProvider>
        </Flex>
      </Flex>
      <InfoTemplate />
      <Selections />
    </div>
  );
}
