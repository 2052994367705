import {
  HomeOutlined,
  WalletOutlined,
  UserOutlined,
  MacCommandOutlined,
  ControlOutlined,
  AppstoreOutlined,
  SettingOutlined
} from "@ant-design/icons";
import React from "react";

const menusAdmin = [
  {
    key: "/",
    icon: React.createElement(HomeOutlined),
    label: `Tổng quan`,
  },
  {
    key: "/user",
    icon: React.createElement(UserOutlined),
    label: `Người dùng`,
  },
  {
    key: "/finances",
    icon: React.createElement(WalletOutlined),
    label: `Tài chính`,
  },
  //   {
  //     key: "/manage",
  //     icon: React.createElement(MacCommandOutlined),
  //     label: `Quản lý`,
  //   },
  {
    key: "/control",
    icon: React.createElement(ControlOutlined),
    label: `Điểu khiển`,
  },
  {
    key: "/template",
    icon: React.createElement(AppstoreOutlined),
    label: `Cấu hình vòng chơi`,
  },
  {
    key: "/setting",
    icon: React.createElement(SettingOutlined),
    label: `Cài đặt`,
  },
];

export { menusAdmin };
