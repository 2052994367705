import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import queryString from "query-string";
import Helpers from "utils/Helpers";
import { userPredicts, userTransactions } from "stores/UserSlice";
import { Table, Typography } from "antd";
import moment from "moment";
import { formatNumberLocal } from "utils";

type IProps = {
  userId?: string;
};

export default function PredictHistories(props: IProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<any>(null);

  const datas = useAppSelector((state) => state.user).userPredicts.datas;
  const meta = useAppSelector((state) => state.user).userPredicts.meta;

  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 10,
    };
  }, [location.search]);

  function fetchData() {
    //@ts-ignore
    const params = { ...filter };
    if (filter) {
      params.userId = props.userId;
      dispatch(userPredicts(params));
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, props.userId]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({ ...Helpers.clean(query) });
    }
  }, [query]);

  const columns: any = [
    {
      title: "Vòng",
      dataIndex: "round",
      key: "round",
      render: (value: string) => `#${value}`,
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Đặt cược",
      dataIndex: "template",
      key: "template",
      render: (value: string, record: any) => {
        return (
          <div>
            <div>
              Loại cược: <strong>{value}</strong>
            </div>
            <div>
              Cược: <strong>{record.selection.name}</strong>
            </div>
          </div>
        );
      },
    },
    {
      title: "Kết quả",
      dataIndex: "roundResult",
      key: "roundResult",
      render: (value: number[]) => `${value.join(", ")}`,
    },
    {
      title: "Tổng tiền",
      dataIndex: "amount",
      key: "amount",
      render: (value: number) => (
        <Typography.Text strong>{`${formatNumberLocal(
          value
        )}K`}</Typography.Text>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "result",
      key: "result",
      render: (value: string) => (
        <Typography.Text
          strong
          type={
            value === "WIN"
              ? "success"
              : value === "LOSE"
              ? "danger"
              : undefined
          }
        >
          {value === "WIN"
            ? "Thắng"
            : value === "LOSE"
            ? "Thua"
            : value === "DRAW"
            ? "Hoà"
            : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Lợi nhuận",
      dataIndex: "netProfit",
      key: "netProfit",
      render: (value: number) => (
        <Typography.Text
          strong
          type={value > 0 ? "success" : value < 0 ? "danger" : undefined}
        >{`${formatNumberLocal(value)}K`}</Typography.Text>
      ),
    },
  ];

  function onChangePage(page: number) {
    navigate({
      search: queryString.stringify({
        ...query,
        type: "predicts",
        page,
      }),
    });
  }

  return (
    <div className="table-wrapper">
      <Table
        className="table-custom table-predict-history"
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        columns={columns}
        pagination={{
          defaultPageSize: filter ? filter.pageSize : 10,
          defaultCurrent: filter ? filter.page : 1,
          current: filter ? filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          showTotal: (total: number) => `Total: ${total}`,
          onChange: (page) => onChangePage(page),
        }}
      />
    </div>
  );
}
