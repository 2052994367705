import { RequestManager } from "utils";
import config from "configs";

const transactions = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/transactions`,
    params
  );
};

const staticTransaction = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/transactions/static`,
      params
  );
};

const accepted = (transactionId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/transactions/${transactionId}/accepted`
  );
};

const canceled = (transactionId: string) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/transactions/${transactionId}/canceled`
  );
};

const exported = {
  transactions,
  accepted,
  canceled,
  staticTransaction
};

export default exported;
