import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Popover,
  Space,
  Spin,
} from "antd";
import { menusAdmin } from "./menus";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import { useEffect, useState } from "react";
import ProfileSlice, { adminInfo } from "stores/ProfileSlice";
import { LogoutOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import MainRouter from "../MainRouter";
import React from "react";
const { Header, Content, Sider } = Layout;

export default function MainLayout() {
  const dispatch = useAppDispatch();
  const { token, admin } = useAppSelector((state) => state.profile);
  const { counterLoading, type, message, description } = useAppSelector(
    (state) => state.common
  );
  const navigate = useNavigate();
  let location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  function logOut() {
    dispatch(ProfileSlice.actions.setToken(""));
    localStorage.removeItem("AccessToken");
    navigate("/login");
  }

  const onClickMenu = (e: any) => {
    navigate(e.key);
    setIsOpen(false);
  };

  useEffect(() => {
    if (token) {
      dispatch(adminInfo());
    }
  }, [token]);

  return (
    <Layout id={"admin"}>
      <Header className="header">
        <div>
          <img src="/images/img_logo.png" alt="" />
        </div>
        <Space className="header-logout-user" align="center">
          <Button type="primary">
            <Space onClick={logOut}>
              <img src="/images/ic_logout.svg" alt="" style={{ width: 19 }} />
              Đăng xuất
            </Space>
          </Button>
          <Avatar size={49} icon={<UserOutlined />} />
        </Space>
        <Popover
          content={
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={["/" + location.pathname.split("/")[1]]}
              items={menusAdmin}
              onClick={onClickMenu}
            />
          }
          title="Menu"
          trigger="click"
          open={isOpen}
          onOpenChange={() => setIsOpen(!isOpen)}
        >
          <div className="header-mobile-menu">
            <MenuOutlined
              style={{ color: "#fff", fontSize: 32 }}
              twoToneColor="#fff"
            />
          </div>
        </Popover>
      </Header>
      <Layout style={{ background: "#e5e5e5" }}>
        <Sider
          style={{
            overflow: "auto",
            height: "calc(100vh - 64px)",
          }}
          // collapsed={true}
          // collapsible
          breakpoint="lg"
          collapsedWidth="0"
          // onBreakpoint={(broken) => {
          //   console.log(broken);
          // }}
          // onCollapse={(collapsed, type) => {
          //   console.log(collapsed, type);
          // }}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={["/" + location.pathname.split("/")[1]]}
            items={menusAdmin}
            onClick={onClickMenu}
          />
        </Sider>
        <Content
          style={{
            marginTop: 10,
            margin: "16px 10px",
            height: "calc(100vh - 96px)",
            overflow: "auto",
          }}
          className="site-layout-background content"
        >
          <Spin spinning={counterLoading > 0 ? true : false}>
            <Outlet />
          </Spin>
        </Content>
      </Layout>
    </Layout>
  );
}
