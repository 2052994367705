import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";
import { orArray, orEmpty, orNull, orNumber } from "utils/Selector";

const initialState = {
  isRefresh: false as boolean,
  users: {
    datas: [] as UserType[],
    meta: defaultMeta as MetaType,
  },
  userSelected: null as UserType | null,
  detail: null as UserType | null,
  userTransactions: {
    datas: [] as UserTransactionType[],
    meta: defaultMeta as MetaType,
  },
  userPredicts: {
    datas: [] as UserPredictType[],
    meta: defaultMeta as MetaType,
  },
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUsers(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        users: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const addUser = createAsyncThunk(
  "users/addUser",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.addUser(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: "Thêm mới tài khoản thành công!",
          })
        );
        return data.data as UserType;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã có lỗi xảy ra!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
        return null;
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const addTransaction = createAsyncThunk(
  "users/addTransaction",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.addTransaction(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: `${
              params.type === "WITHDRAW"
                ? "Rút tiền"
                : params.type === "DEPOSIT"
                ? "Nạp tiền"
                : ""
            } thành công!`,
          })
        );
        return "success" as string;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã có lỗi xảy ra!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
        return null;
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const detailUser = createAsyncThunk(
  "users/detailUser",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.detailUser(userId);
      const { data } = response;
      return data.data as UserType;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.updateUser(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Thành công!",
            description: "Cập nhật thành công!",
          })
        );
        return data.data;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Đã xảy ra lỗi!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const userTransactions = createAsyncThunk(
  "users/userTransactions",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.userTransactions(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const userPredicts = createAsyncThunk(
  "users/userPredicts",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.userPredicts(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<boolean>) => {
      state.isRefresh = action.payload;
    },
    setUserSelected: (state, action: PayloadAction<UserType | null>) => {
      state.userSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.users.meta = action.payload.meta;
        state.users.datas = action.payload.users;
      }
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      if (action.payload) {
        let arr = [...state.users.datas];
        let total = state.users.meta.total + 1;

        arr.unshift(action.payload);
        state.users.datas = arr;
        state.users.meta.total = total;
      }
    });
    builder.addCase(addTransaction.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(detailUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(userTransactions.fulfilled, (state, action) => {
      if (action.payload) {
        state.userTransactions.meta = action.payload.meta;
        state.userTransactions.datas = action.payload.datas;
      }
    });
    builder.addCase(userPredicts.fulfilled, (state, action) => {
      if (action.payload) {
        state.userPredicts.meta = action.payload.meta;
        state.userPredicts.datas = action.payload.datas;
      }
    });
  },
});

export default UserSlice;
