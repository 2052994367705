import {
  ConfigProvider,
  Divider,
  Flex,
  Input,
  InputNumber,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import TemplateSlice from "stores/TemplateSlice";

export default function InfoTemplate() {
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.template);

  const setItemDetail = (value: number | null, item: string) => {
    if (detail) {
      let dfDetail = { ...detail };
      if (item === "min") {
        dfDetail = { ...dfDetail, min: value || dfDetail.min };
      }
      if (item === "limit") {
        dfDetail = { ...dfDetail, limit: value || dfDetail.limit };
      }
      dispatch(TemplateSlice.actions.setDetail(dfDetail));
    }
  };

  return (
    <div style={{ background: "#fff", padding: 24 }}>
      <Typography.Title level={4}>Cài đặt hạn mức cược</Typography.Title>
      <Divider />
      <Flex
        justify="flex-start"
        gap="small"
        className="items-center mobile-flex-col"
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#009292",
              borderRadius: 10,
            },
          }}
        >
          <Flex vertical>
            <Typography.Text strong>Tối thiểu cược (K)</Typography.Text>
            <InputNumber
              min={0}
              value={detail?.min}
              onChange={(value) => setItemDetail(value, "min")}
              style={{ width: 300 }}
              placeholder="Tối thiểu"
            />
          </Flex>
          <Flex vertical>
            <Typography.Text strong>Hạn mức cược (K)</Typography.Text>
            <InputNumber
              min={0}
              value={detail?.limit}
              onChange={(value) => setItemDetail(value, "limit")}
              style={{ width: 300 }}
              placeholder="Hạn mức"
            />
          </Flex>
        </ConfigProvider>
      </Flex>
    </div>
  );
}
