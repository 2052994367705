import React, { useEffect, useState } from "react";
import { useAppSelector } from "stores";

export default function LiveStream() {
  const { current } = useAppSelector((state) => state.game);

  const [label, setLabel] = useState("");

  useEffect(() => {
    if (current.status === "OPEN") setLabel("Bắt đầu đặt cược");
    if (current.status === "CLOSE") setLabel("Dừng đặt cược");
    if (current.status === "SETTLED") setLabel("Đang trả thưởng");
    if (current.status === "CANCELED") setLabel("Vòng chơi huỷ");
  }, [current.status]);

  return (
    <div
      className="control-live"
      style={{ position: "relative", maxWidth: 768 }}
    >
      <div
        style={{
          position: "relative",
          height: 433,
          width: 768,
        }}
      >
        <iframe
          src="https://customer-d4a8xa8of3nmkast.cloudflarestream.com/97f6d71031977d1d14984ee956a4a19b/iframe?autoplay=true&muted=true"
          style={{
            border: "none",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true}
        ></iframe>
        <div
          style={{
            background: "white",
            position: "absolute",
            right: 0,
            bottom: 0,
            width: current.status === "CLOSE" ? "100%" : 250,
            height: current.status === "CLOSE" ? "100%" : 250,
          }}
          // className={`absolute bg-white right-0 bottom-0 ${
          //   current.status === "CLOSE" ? "w-full h-full" : "w-[250px] h-[250px]"
          // }`}
        >
          <iframe
            src="https://customer-d4a8xa8of3nmkast.cloudflarestream.com/0d939ecf492af27ca6649d6d5ca7c8f3/iframe?autoplay=true&muted=true"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          border: "1px solid #d3adf7",
          padding: "10px 20px",
          background: "#f9f0ff",
        }}
      >
        <span style={{ color: "#531dab", fontWeight: 700 }}>
          Vòng: #{current.shortId}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          border: "1px solid #d3adf7",
          padding: "10px 20px",
          background: "#f9f0ff",
        }}
      >
        <span style={{ color: "#531dab", fontWeight: 700 }}>{label}</span>
      </div>
    </div>
  );
}
