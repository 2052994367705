import { Routes, Route } from "react-router-dom";
import MainLayout from "./MainLayout/MainLayout";
import RequireAuth from "components/RequireAuth";
import Login from "pages/login/LoginPage";
import Home from "pages/home";
import FinancesPage from "pages/finances";
import UserPage from "pages/user";
import ManagePage from "pages/manage";
import ControlPage from "pages/control";
import DetailUserPage from "pages/user/detail";
import TemplatePage from "pages/template";
import TemplateDetailPage from "pages/template/detail";
import Setting from "pages/setting";

function LayoutDemo() {
  return <MainLayout />;
}

export default function MainRouter() {
  return (
    <Routes>
      <Route element={<LayoutDemo />}>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserPage />
            </RequireAuth>
          }
        />
        <Route
          path="/user/:id"
          element={
            <RequireAuth>
              <DetailUserPage />
            </RequireAuth>
          }
        />

        <Route
          path="/finances"
          element={
            <RequireAuth>
              <FinancesPage />
            </RequireAuth>
          }
        />
        <Route
          path="/manage"
          element={
            <RequireAuth>
              <ManagePage />
            </RequireAuth>
          }
        />
        <Route
          path="/control"
          element={
            <RequireAuth>
              <ControlPage />
            </RequireAuth>
          }
        />
        <Route
          path="/template"
          element={
            <RequireAuth>
              <TemplatePage />
            </RequireAuth>
          }
        />
        <Route
          path="/template/:id"
          element={
            <RequireAuth>
              <TemplateDetailPage />
            </RequireAuth>
          }
        />
        <Route
          path="/setting"
          element={
            <RequireAuth>
              <Setting />
            </RequireAuth>
          }
        />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  );
}
