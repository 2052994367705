import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";
import { orArray, orEmpty, orNull, orNumber } from "utils/Selector";
import GeneralApi from "../apis/GeneralApi";

const initialState = {
    isRefresh: false as boolean,
    general: {
        totalWithdraw: 0 as number,
        totalDeposit: 0 as number,
        totalUser: 0 as number,
        totalBalance: 0 as number,
        totalWinAmount: 0 as number,
        totalLoseAmount: 0 as number,
        totalPredictAmount: 0  as number,
        totalFee: 0 as number
    },
    transaction: {
        totalDeposit:  0 as number,
        totalWithdraw:  0 as number,
        totalPendingDeposit: 0 as number,
        totalPendingWithdraw: 0 as number,
        totalCancelDeposit: 0 as number,
        totalCancelWithdraw: 0 as number,
    },
    dailyTransactions: [] as any[]
};

export const getGeneralData = createAsyncThunk(
    "generals/getGeneralData",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await GeneralApi.getGeneralData(params);
            const { data } = response;
            return {
                totalWithdraw: data.data.totalWithdraw,
                totalDeposit: data.data.totalDeposit,
                totalUser: data.data.totalUser,
                totalBalance: data.data.totalBalance,
                totalWinAmount: data.data.totalWinAmount,
                totalLoseAmount: data.data.totalLoseAmount,
                totalPredictAmount: data.data.totalPredictAmount,
                totalFee: data.data.totalFee
            };
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const getTransactionData = createAsyncThunk(
    "generals/getTransactionData",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await GeneralApi.getTransactionData(params);
            const { data } = response;
            return {
                totalDeposit:  data.data.totalDeposit,
                totalWithdraw: data.data.totalWithdraw,
                totalPendingDeposit: data.data.totalPendingDeposit,
                totalPendingWithdraw: data.data.totalPendingWithdraw,
                totalCancelDeposit: data.data.totalCancelDeposit,
                totalCancelWithdraw: data.data.totalCancelWithdraw,
            };
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const getDailyTransactionData = createAsyncThunk(
    "generals/getDailyTransactionData",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await GeneralApi.getDailyTransactionData(params);
            const { data } = response;
            return data.data as any[];
        } catch (error) {
            return [];
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

const GeneralSlice = createSlice({
    name: "generals",
    initialState,
    reducers: {
        setIsRefresh: (state, action: PayloadAction<boolean>) => {
            state.isRefresh = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getGeneralData.fulfilled, (state, action) => {
            if (action.payload) {
                state.general = action.payload;
            }
        });
        builder.addCase(getTransactionData.fulfilled, (state, action) => {
            if (action.payload) {
                state.transaction = action.payload;
            }
        });
        builder.addCase(getDailyTransactionData.fulfilled, (state, action) => {
            if (action.payload) {
                state.dailyTransactions = action.payload;
            }
        });
    },
});

export default GeneralSlice;
