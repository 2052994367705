import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { formatNumberLocal } from "utils";

export default function Balance() {
  const { detail } = useAppSelector((state) => state.user);
  return (
    <div style={{ background: "#fff", padding: 24, height: "100%" }}>
      <Descriptions column={6} title="Thông tin tài khoản">
        <Descriptions.Item span={6} label="Số dư ví">
          <Typography.Text strong>
            {formatNumberLocal(detail?.userCoin.balance)}K
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Tổng nạp">
          <Typography.Text strong>
            {formatNumberLocal(detail?.userCoin.totalDepositAmount)}K
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Tổng rút">
          <Typography.Text strong>
            {formatNumberLocal(detail?.userCoin.totalWithdrawAmount)}K
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
